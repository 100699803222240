import React, { useContext, useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import _ from 'lodash'
import { navigate, Link } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { Form } from 'semantic-ui-react'
import Slider from 'react-slick'
import { useAtom, useAtomValue } from 'jotai'
import { useForm } from 'react-hook-form'
import { saleMapAtom } from '../../states/saleData'

import * as styles from '../../components/ProductSlider/ProductSlider.module.scss'
import {
  currencyFormatter,
  getProductById,
  getBoxAccessory,
  getImageFromNodeFile,
  getNodeNameByData,
  getImageNodesByProduct,
  getLastPath,
  getSaleSortedProducts,
} from '../../utils/utils'
import useOrderItems from '../../hooks/useOrderItems'
import { accessoriesAtom } from '../../states/selectedProduct'
import TailwindLayout from '../../components/Layouts/TailwindLayout'
import { accessories as initAccessories } from '../../utils/accessories'
import Layout from '../../components/Layouts/cakeDefault'
import { AccessoriesSlider } from '../../components/DatHangPage'
import MotionSlider from '../../components/ProductSlider/MotionSlider'
import CartIcon from '../../components/Base/CartIcon'
import tracker from '../../utils/tracker'
import { ImageDataContext } from '../../states/imageDataContext'
import useNoteCartChange from '../../hooks/useNoteCartChange'

const settings = {
  className: styles.productDetail,
  dots: true,
  arrows: false,
  dotsClass: 'slick-dots slick-thumb',
  centerMode: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}
const Product = ({ location }) => {
  const { saveCartChangeNote } = useNoteCartChange()
  const productId = getLastPath(location.pathname)

  const saleMap = useAtomValue(saleMapAtom)

  const staticProduct = getProductById({ id: productId }) || {}
  const product =
    _.head(getSaleSortedProducts([staticProduct], saleMap)) || staticProduct
  const [currentVariant, setCurrentVariant] = useState(
    product?.variants[0] || {}
  )
  const [, setAccessories] = useAtom(accessoriesAtom)
  const { addToCart } = useOrderItems()

  const {
    // liên quan đến phần validate chữ viết đang tạm tắt
    // register,
    handleSubmit,
    // formState: { errors },
  } = useForm()
  const handleSetVariant = (variant) => {
    setCurrentVariant(variant)
  }
  // todo: sửa lại dùng addtoCartAfterAddons
  const onSubmit = (data) => {
    tracker.addToCart({ value: currentVariant.salePrice })
    addToCart({ product, selectedVariant: currentVariant })
    saveCartChangeNote(
      `Product Detail Add: ${currentVariant.sku} of ${product.description} (Single)`
    )
    navigate('/checkout')
  }

  useEffect(() => {
    const boxAccessory = getBoxAccessory({
      cakeSku: currentVariant?.sku,
      cakeSizeName: currentVariant?.sizeName,
    })
    // bỏ hộp phụ kiện
    setAccessories(initAccessories)
    // thêm hộp phụ kiện theo size bánh
    boxAccessory &&
      setAccessories((preState) => {
        return [boxAccessory, ...preState]
      })
  }, [currentVariant, setAccessories])

  const data = useContext(ImageDataContext)
  const nodeName = getNodeNameByData(data)
  const relatedImages =
    product && getImageNodesByProduct(data, nodeName, product.id)
  const keywords = `bánh sinh nhật bánh kem ${product?.group}`

  return (
    <TailwindLayout>
      <Layout
        endPoint={`products${product ? `/${product.id}` : ''}`}
        title={
          product
            ? `Savor Cake | ${_.capitalize(product.description) || productId}`
            : ''
        }
        keywords={keywords}
      >
        {product ? (
          <div tw="grid grid-cols-1 p-4 my-5 mx-auto max-w-5xl sm:(grid-cols-2 grid-rows-1 border-gray-400 rounded-3xl border shadow-md mt-14) sm:p-14">
            <CartIcon />
            <div tw="row-span-1 sm:(col-span-1 col-start-1 ) md:m-4">
              <div tw="w-full">
                <Slider {...settings}>
                  {product.image || currentVariant.image}
                  {_.map(relatedImages, (image) => {
                    const { imagePath, imageName } = getImageFromNodeFile(
                      image,
                      nodeName
                    )
                    const nodeImage = getImage(imagePath)
                    return <GatsbyImage image={nodeImage} alt={imageName} />
                  })}
                </Slider>
              </div>
              <div tw="mt-6 ">
                <h2 tw="hidden sm:block font-bold font-savor-cake-primary-lexend text-savor-cake-primary-green-avocado-0 text-2xl">
                  Mô tả mẫu bánh sinh nhật
                </h2>
                <h1 tw="block sm:hidden font-bold font-savor-cake-primary-lexend text-savor-cake-primary-green-avocado-0 text-2xl">
                  {product.description}
                </h1>
                <p tw="font-savor-cake-secondary-quicksand text-base font-semibold">
                  {product.content ||
                    currentVariant.content ||
                    'Sản phẩm không tồn tại. Quý khách vui lòng tham khảo các mẫu bánh khác của Savor Cake tại Menu bánh sinh nhật ạ.'}
                </p>
              </div>
            </div>
            <div tw="row-span-1 sm:(col-span-1 col-start-2 ml-3)">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <h2 tw="hidden sm:block font-bold font-savor-cake-primary-lexend text-savor-cake-primary-green-avocado-0 text-3xl">
                    {product.description}
                  </h2>
                  {currentVariant.normalPrice || currentVariant.salePrice ? (
                    <MotionSlider key={currentVariant.normalPrice}>
                      <div tw="font-savor-cake-secondary-quicksand font-semibold text-savor-cakes-grey-400 line-through text-base">
                        {currencyFormatter.format(currentVariant.normalPrice)}
                      </div>
                      <div tw="font-savor-cake-secondary-quicksand font-bold text-savor-cake-primary-green-avocado-0 text-xl py-0 my-0">
                        {currencyFormatter.format(currentVariant.salePrice)}
                      </div>
                    </MotionSlider>
                  ) : null}
                </div>
                <div>
                  <Label>Chọn size bánh bắt buộc</Label>
                  <div tw="flex flex-row space-x-2 py-6">
                    {_.map(product.variants, (variant, i) => {
                      return (
                        <Variant
                          type="button"
                          key={i}
                          onClick={() => handleSetVariant(variant)}
                          currentVariant={
                            currentVariant.size === variant.size ? true : false
                          }
                        >
                          {variant.size}
                        </Variant>
                      )
                    })}
                  </div>
                </div>
                <div>
                  <Label>Chọn phụ kiện</Label>
                  <MotionSlider key={currentVariant?.size}>
                    <AccessoriesSlider
                      sliderStyle={styles.accessoriesSliderSmall}
                    />
                  </MotionSlider>
                </div>
                {currentVariant.sku ? (
                  // use on submit
                  <button
                    type="submit"
                    tw="font-bold w-full px-10 sm:px-20 py-5 bg-savor-cake-primary-green-avocado-0 text-white text-base leading-tight
                            uppercase rounded
                            hover:shadow-lg hover:-translate-y-1 focus:shadow-lg focus:outline-none focus:ring-0
                            disabled:bg-gray-300 disabled:text-gray-500
                            active:bg-blue-800 active:shadow-lg transition duration-300 ease-in-out cursor-pointer mx-auto text-center"
                  >
                    Mua ngay
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      if (window.gtag) {
                        window.gtag('event', 'select_promotion', {
                          event_label: 'product_detail',
                          creative_name: 'product_detail',
                        })
                      }
                      window.open('https://m.me/savor.vn', '_blank')
                    }}
                    tw="font-bold w-full px-10 sm:px-20 py-5 bg-savor-cake-primary-green-avocado-0 text-white text-base leading-tight
                        uppercase rounded
                        hover:shadow-lg hover:-translate-y-1 focus:shadow-lg focus:outline-none focus:ring-0
                        disabled:bg-gray-300 disabled:text-gray-500
                        active:bg-blue-800 active:shadow-lg transition duration-300 ease-in-out cursor-pointer mx-auto text-center"
                  >
                    Tư vấn
                  </button>
                )}
              </Form>
            </div>
          </div>
        ) : (
          <NotFound />
        )}
      </Layout>
    </TailwindLayout>
  )
}

export default Product

const Label = tw.div`bg-savor-cake-secondary-green-matcha-100 text-base font-savor-cake-primary-lexend font-semibold my-2 p-1 rounded-sm`
const DefaultVariant = tw.button`border-2 rounded-md px-3 py-2 cursor-pointer font-semibold`
const Variant = styled(DefaultVariant)`
  ${({ currentVariant }) =>
    currentVariant
      ? tw`text-savor-cake-primary-green-avocado-100 border-savor-cake-primary-green-avocado-0 cursor-default`
      : tw`border-savor-cakes-grey-400 text-savor-cakes-grey-400 hover:text-savor-cake-secondary-blueberry-0 hover:border-savor-cake-primary-green-avocado-0`}
`
const NotFoundText = tw.div`h-44 sm:h-96 flex justify-center items-center flex-col text-base`
const NotFound = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 20000)
    return () => clearTimeout(timer)
  }, [])

  if (isLoading) {
    return <NotFoundText>Đang tải sản phẩm...</NotFoundText>
  }
  return (
    <NotFoundText>
      Không tìm thấy sản phẩm
      <Link to="/">
        <div tw=" text-savor-cake-primary-green-avocado-0 text-base font-bold">
          Quay lại trang bánh sinh nhật
        </div>
      </Link>
    </NotFoundText>
  )
}
